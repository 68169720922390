import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

import featherLogo from '../../../assets/img/logos/png/featherLogo.png';

import Researchers from './Researchers';

const calculateMaxItemsPerPage = function (width) {
    return width < 992 ? (width < 768 ? 3 : 4) : 6;
};

export default function PastResearchers({ pastResearchersList }) {
    const [active, setActive] = useState(1);
    const [, setScreenSize] = useState({ width: window.innerWidth });
    const [maxItemsPerPage, setMaxItemsPerPage] = useState(
        calculateMaxItemsPerPage(window.innerWidth)
    );
    const [visibleResearchers, setVisibleResearchers] = useState(
        pastResearchersList.slice(0, maxItemsPerPage)
    );
    const [pages, setPages] = useState([]);

    const totalPages = useMemo(() => {
        return Math.ceil(pastResearchersList.length / maxItemsPerPage);
    }, [pastResearchersList.length, maxItemsPerPage]);

    const handlePageClick = (pageNumber) => {
        setActive(pageNumber);
    };

    const updatePaginationItems = useCallback(() => {
        const newPages = [
            <Pagination.Prev
                key="prev"
                disabled={active === 1}
                onClick={() => handlePageClick(active - 1)}
            />,
        ];

        for (let page = 1; page <= totalPages; page++) {
            newPages.push(
                <Pagination.Item
                    key={page}
                    active={page === active}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        newPages.push(
            <Pagination.Next
                key="next"
                disabled={totalPages === active}
                onClick={() => handlePageClick(active + 1)}
            />
        );
        setPages(newPages);
    }, [active, totalPages]);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setScreenSize({ width: newWidth });
            const newMaxItemsPerPage = calculateMaxItemsPerPage(newWidth);
            setMaxItemsPerPage(newMaxItemsPerPage);
            const newTotalPages = Math.ceil(
                pastResearchersList.length / newMaxItemsPerPage
            );
            if (active > newTotalPages) {
                setActive(newTotalPages);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [active, pastResearchersList]);

    useEffect(() => {
        const newVisibleResearchers = pastResearchersList.slice(
            (active - 1) * maxItemsPerPage,
            active * maxItemsPerPage
        );
        setVisibleResearchers(newVisibleResearchers);
        updatePaginationItems();
    }, [active, maxItemsPerPage, pastResearchersList, updatePaginationItems]);

    return (
        <>
            {visibleResearchers.map((researcher) => (
                <Researchers
                    className="mb-2"
                    key={researcher.id}
                    imgSrc={researcher?.avatar_url || featherLogo}
                    name={researcher?.display_name || researcher?.name}
                    link={researcher?.profile_url}
                    title={researcher?.title}
                    university={researcher?.university}
                />
            ))}

            <Col
                lg={12}
                className="d-flex justify-content-center mt-lg-2 mt-sm-1"
            >
                <Pagination>{pages}</Pagination>
            </Col>
        </>
    );
}

PastResearchers.propTypes = {
    pastResearchersList: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            university: PropTypes.string,
            imgSrc: PropTypes.string,
            link: PropTypes.string,
        })
    ).isRequired,
};
