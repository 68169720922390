import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import rewards from '../../assets/img/faq/rewards.png';
import support from '../../assets/img/faq/support.png';
import love from '../../assets/img/faq/love.png';

import FaqCard from './faqCard';
import './faq.css';

export default function FAQ() {
    return (
        <section id="faq" className="">
            <Container fluid>
                <Row className="faq-block px-3">
                    <Col className="">
                        <h2 className="faq-title text-center h1 text-primary">
                            Frequently Asked Questions (FAQ)
                        </h2>
                        <p className="faq-subtitle fs-3">
                            Have a question about Roadrunner Connect? Find
                            answers to some of the most common questions below.
                        </p>
                    </Col>
                </Row>
                <Row className="system-cards-block">
                    <Col className="system-cards-container">
                        <Col>
                            <FaqCard
                                title={'General Questions'}
                                description={`
                  <b>Q: How can I Download the app?</b><br/>
                  A: You can download the app from the iOS App Store or Google Play Store by searching for "Roadrunner Connect". There are also buttons at the top of our About section.<br/><br/>
                  <b>Q: What is Roadrunner Connect?</b><br/>
                  A: Roadrunner Connect is an app designed to enhance community engagement at MSU Denver by connecting the community with a variety of events across the campus.<br/><br/>
                  <b>Q: Do I have to be an MSU student to use the app?</b><br/>
                  A: No, Roadrunner Connect is available to anyone interested in campus events and considers themself part of the community.
                `}
                                imgSrc={love}
                            />
                        </Col>
                        <Col>
                            <FaqCard
                                title={'App Features'}
                                description={`
                  <b>Q: How does the check-in system work?</b><br/>
                  A: The check-in system lets users mark their attendance at events by using their location. This helps in earning
                  rewards and participating in contests.<br/><br/>
                  <b>Q: What can I do on the app?</b><br/>
                  A: Users can check into events and upload a photo or leave a comment for them.
                  There are also contests for the public leaderboard, and for the giveaways that departments host.
                  To get entries into giveaways or to get higher on the leaderboard, users can get feathers by checking into events,
                  uploading photos/comments, completing wekely challenges, or completing badges.<br/><br/>
                `}
                                imgSrc={rewards}
                            />
                        </Col>
                        <Col>
                            <FaqCard
                                title={'Technical Support'}
                                description={`
                  <b>Q: I'm a department, how can I create a giveaway?</b><br/>
                  A: Departments can create giveaways by contacting our team at contact@roadrunnerconnect.co.<br/><br/>
                  <b>Q: How do I report a bug or issue with the app?</b><br/>
                  A: You can report bugs or issues by emailing contact@roadrunnerconnect.co with a description of the problem.
                  Please include any relevant screenshots or details to help us resolve the issue.<br/><br/>
                  <b>Q: I forgot my password. How can I reset it?</b><br/>
                  A: Click on the "Forgot Password" link on the login screen, enter your registered email address, and follow the
                  instructions sent to your email to reset your password.
                `}
                                imgSrc={support}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="system-cards-block-background" />
            </Container>
        </section>
    );
}
