import React, { useEffect } from 'react';

export default function PrivacyPolicy() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.id = 'termly-jssdk';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div
                name="termly-embed"
                data-id="83471ba9-2f36-4a87-a277-1cd8736e5644"
            />
        </div>
    );
}
