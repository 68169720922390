import React, { useEffect } from 'react';

export default function EULAPolicy() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.id = 'termly-jssdk';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div
                name="termly-embed"
                data-id="8d897593-83a0-45f7-b663-36b6229d945d"
            />
        </div>
    );
}
