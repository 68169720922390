import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Routes, Route } from 'react-router-dom';

import DefaultPolicyContent from './DefaultPolicyContent';
import EULAPolicy from './EULAPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import './Policies.css';

export default function Policies() {
    return (
        <section id="policies">
            <Container fluid>
                <Row>
                    <Col md={3} className="policies-menu">
                        <h4 className="policies-menu-title">
                            Legal Information
                        </h4>
                        <ul className="policies-menu-list">
                            <li>
                                <Link to="/policies/eula" className="button-17">
                                    End User License Agreement (EULA)
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/policies/privacy"
                                    className="button-17"
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col md={9} className="policies-content">
                        <Routes>
                            <Route index element={<DefaultPolicyContent />} />
                            <Route path="eula" element={<EULAPolicy />} />
                            <Route path="privacy" element={<PrivacyPolicy />} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
