import React from 'react';
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/custom.scss';
import './App.css';
import NavBar from './navBar/NavBar';
import Homepage from './homePage/HomePage';
import About from './about/About';
import Policies from './policies/Policies';
import ProjectUpdates from './projectUpdates/ProjectUpdates';
import ContactInfo from './contactUs/ContactInfo';
import Footer from './footer/Footer';

function App() {
    return (
        <div className="App">
            <header id="App-header">
                <NavBar />
            </header>
            <main id="App-main" className="">
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/policies/*" element={<Policies />} />
                    <Route
                        path="/project-updates"
                        element={<ProjectUpdates />}
                    />
                    <Route path="/contact" element={<ContactInfo />} />
                </Routes>
            </main>
            <footer id="App-footer">
                <Footer />
            </footer>
        </div>
    );
}

export default App;
