import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import rowdyTeam from '../assets/img/rowdyTeam.jpg';
import catSelfie from '../assets/img/catSelfie.jpg';

import './ProjectUpdates.css';

const ProjectCard = ({ imageSrc, title, description }) => (
    <Card className="project-card">
        <Card.Img variant="top" src={imageSrc} className="project-card-img" />
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
        </Card.Body>
    </Card>
);
ProjectCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default function ProjectUpdates() {
    const updates = [
        {
            imageSrc: rowdyTeam,
            title: 'Internship Completion',
            description: `The development team has now completed their 10-week internship for Roadrunner
            Connect on August 9th, 2024! Their hard work in such a short time frame has positioned
            the app to be ready for deployment by its expected launch, Fall 2024 Commencement.`,
        },
        {
            imageSrc: catSelfie,
            title: 'Continued Development Funding',
            description: `We are excited to announce that funding has been secured for two team members
            from the internship, Cece Housh and Kade Shockey, to continue working on Roadrunner Connect.
            This further ensures that the app will be fully deployed by Fall 2024 Commencement.`,
        },
    ];

    return (
        <section id="projectUpdates">
            <Container className="py-4">
                <h2 className="text-center mb-5">Project Updates</h2>
                <Row className="project-row">
                    {updates.map((update, index) => (
                        <Col key={index} md={6} className="mb-4">
                            <ProjectCard {...update} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}
