import React from 'react';

import Mission from './mission/Mission';
import Faq from './faq/faq';
import Team from './team/Team';

const About = () => (
    <div>
        <Mission />
        <Team />
        <Faq />
    </div>
);
export default About;
