import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

import './Team.css';
import teamList from '../../assets/team/teams.json';
import featherLogo from '../../assets/img/logos/png/featherLogo.png';

import Leaders from './helper/Leaders';
import Researchers from './helper/Researchers';
import PastResearchers from './helper/PastResearchers';
import getGravatarData from './helper/gravatar';

export default function Team() {
    const [leadersList, setLeadersList] = useState([]);
    const [researchersList, setResearchersList] = useState([]);
    const [pastResearchersList, setPastResearchersList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGravatarData = async () => {
            try {
                const updatedTeamList = await Promise.all(
                    teamList.map(async (item) => {
                        const data = await getGravatarData(
                            item.profileIdentifier
                        );
                        return {
                            ...item,
                            display_name: data?.display_name,
                            avatar_url: data?.avatar_url,
                            job_title: data?.job_title,
                            profile_url: data?.profile_url,
                        };
                    })
                );

                const filterAndSort = (members, current) =>
                    members
                        .filter((member) => member.current === current)
                        .sort((a, b) => {
                            const lastNameA = a.name
                                .split(' ')
                                .slice(-1)[0]
                                .toLowerCase();
                            const lastNameB = b.name
                                .split(' ')
                                .slice(-1)[0]
                                .toLowerCase();
                            return lastNameA.localeCompare(lastNameB);
                        });

                const filteredLeaders = updatedTeamList.filter(
                    (member) => member.leader === true
                );
                const filteredResearchers = updatedTeamList.filter(
                    (member) => member.leader === false
                );
                const currentResearchers = filterAndSort(
                    filteredResearchers,
                    true
                );
                const pastResearchers = filterAndSort(
                    filteredResearchers,
                    false
                );

                setLeadersList(filteredLeaders);
                setResearchersList(currentResearchers);
                setPastResearchersList(pastResearchers);
            } catch (error) {
                throw new Error(
                    'Error fetching Gravatar data: ' + error.message
                );
            } finally {
                setLoading(false);
            }
        };

        fetchGravatarData().then(() => {});
    }, []);

    if (loading) {
        return <div>Loading team data...</div>;
    }

    return (
        <section id="team">
            <Container className="team-container">
                <Row className="title1-row">
                    <div className="draw-line " />
                    <h1 className="text-primary my-5">Meet the Team</h1>
                </Row>
                <Row className="leaders-row">
                    {leadersList.map((leader, index) => (
                        <Leaders
                            key={index}
                            imgSrc={leader?.avatar_url || featherLogo}
                            name={leader?.display_name || leader?.name}
                            link={leader?.profile_url}
                            title={leader?.title}
                            university={leader?.university}
                        />
                    ))}
                </Row>
                <Row className="title2-row py-3">
                    <div className="text-primary">
                        <span>and our </span>
                        <h4>Student Full-Stack Developers</h4>
                    </div>
                </Row>
                <Row className="researchers-row px-5">
                    {researchersList.map((researcher, index) => (
                        <Researchers
                            key={index}
                            imgSrc={researcher?.avatar_url || featherLogo}
                            name={researcher?.display_name || researcher?.name}
                            link={researcher?.profile_url}
                            title={researcher?.title}
                            university={researcher?.university}
                        />
                    ))}
                </Row>
                <Row className="pastResearchers-row justify-content-center px-5">
                    <div className="line-text mb-lg-3 text-center">
                        <span className="line" />
                        <span className="px-1 line-text-content">
                            Past Students
                        </span>
                        <span className="line" />
                    </div>
                    <PastResearchers
                        pastResearchersList={pastResearchersList}
                        featherLogo={featherLogo}
                    />
                    <div className="my-5 line" />
                </Row>
            </Container>
        </section>
    );
}
