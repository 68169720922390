import React from 'react';

export default function DefaultPolicyContent() {
    return (
        <div>
            <h3>Welcome to Our Policies Page</h3>
            <p>
                At Roadrunner Connect, we believe in transparency and trust. Our
                policies are designed to protect your rights and explain our
                practices clearly. Here&apos;s what you&apos;ll find:
            </p>
            <ul>
                <li>
                    <strong>End User License Agreement (EULA):</strong> The
                    guidelines for using our services.
                </li>
                <li>
                    <strong>Privacy Policy:</strong> How we collect, use, and
                    protect your personal information.
                </li>
                <li>
                    <strong>Cookies Policy:</strong> Information about the
                    cookies we use and why.
                </li>
            </ul>
            <p>
                We encourage you to read these policies to understand your
                rights and our commitments. If you have any questions, please
                don&apos;t hesitate to contact us. Your trust is important to
                us, and we&apos;re committed to maintaining it through clear
                communication and ethical practices.
            </p>
            <p>
                Please select a policy from the menu on the left to view its
                contents.
            </p>
        </div>
    );
}
