import React from 'react';
import './HomePage.css';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

import ContactInfo from '../contactUs/ContactInfo';

export default function HomePage() {
    const importAllImages = (requireContext) =>
        requireContext.keys().map((key, index) => ({
            id: index + 1,
            src: requireContext(key),
        }));
    const imgSrcList = importAllImages(
        require.context('../assets/img/carousel', false, /\.(png|jpe?g|svg)$/)
    );

    return (
        <section id="landingpage">
            <section id="homepage">
                <Container>
                    <Row>
                        <Col
                            className="cta-block"
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <h1 className="cta-title text-primary h1">
                                Roadrunner Connect
                            </h1>
                            <p className="cta-description text-primary">
                                <span>
                                    Discover a vibrant community with Roadrunner
                                    Connect, your interactive guide to the
                                    events and activities that bring our campus
                                    to life.
                                    <br />
                                    Connect, engage, and thrive.
                                </span>
                            </p>
                            <a href="/about" id="btn-dont-show-on-mobile">
                                <button className="btn cta-btn">
                                    Our Mission
                                </button>
                            </a>
                        </Col>
                        <Col
                            className="carousel-block"
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Carousel controls={false}>
                                {imgSrcList.map((imgSrc) => (
                                    <Carousel.Item
                                        interval={4000}
                                        className="image-contaner"
                                        key={imgSrc.id}
                                    >
                                        <div className="image-contaner-div">
                                            <img
                                                className="center"
                                                width={748}
                                                height={412}
                                                alt="748x412"
                                                src={imgSrc.src}
                                            />
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                    <a href="/about" id="btn-show-on-mobile">
                        <button className="btn cta-btn">Our Mission</button>
                    </a>
                </Container>
            </section>
            <ContactInfo />
        </section>
    );
}
