import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import SVG icons
import relevanceIcon from '../../assets/img/icons/svg/relevance.svg';
import impactIcon from '../../assets/img/icons/svg/impact.svg';
import methodsIcon from '../../assets/img/icons/svg/methods.svg';
// Import button icons
// import appleIcon from '../../assets/download/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
// import androidIcon from '../../assets/download/GetItOnGooglePlay_Badge_Web_color_English.svg';

import MissionItem from './MissionItem';
import './Mission.css';

const Mission = () => (
    <section id="mission" className="py-5 bg-light">
        <Container className="mission-container">
            <Row className="mb-5 mission-heading-container px-3">
                <Col>
                    <h2 className="h1 text-center text-primary">Our Mission</h2>
                    <p className="mission-subheading fs-4">
                        Our mission is to enhance community engagement at MSU
                        Denver by providing an interactive campus map
                        application. We aim to connect students, faculty, and
                        community members with arts, athletics, and other
                        cultural events across the campus, fostering a vibrant
                        and connected community.
                    </p>
                    {/*<div className="d-flex justify-content-center align-items-end">*/}
                    {/*    <button className="icon-btn">*/}
                    {/*        <img*/}
                    {/*            src={appleIcon}*/}
                    {/*            alt="Download on the App Store"*/}
                    {/*            className="app-store-badge"*/}
                    {/*        />*/}
                    {/*    </button>*/}
                    {/*    <button className="icon-btn">*/}
                    {/*        <img*/}
                    {/*            src={androidIcon}*/}
                    {/*            alt="Get it on Google Play"*/}
                    {/*            className="google-play-badge"*/}
                    {/*        />*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </Col>
            </Row>
            <Row>
                <Col>
                    <MissionItem
                        icon={relevanceIcon}
                        title="Engagement"
                        description="Roadrunner Connect simplifies the discovery and participation in a wide range of events,
                        from arts to athletics, through a user-friendly, map-driven interface."
                    />
                    <MissionItem
                        icon={impactIcon}
                        title="Impact"
                        description="By connecting people through events and activities, Roadrunner Connect fosters a sense of
                        community and belonging, encouraging more active participation in campus life."
                    />
                    <MissionItem
                        icon={methodsIcon}
                        title="Features"
                        description="The app includes a check-in system, photo uploads, and comment sections for events,
                        along with a rewards system to incentivize participation through earning feathers, badges, and giveaways.
                        Additionally, the custom-built campus map makes it easy to find events and activities."
                    />
                </Col>
            </Row>
        </Container>
    </section>
);
export default Mission;
